import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './styleAbout.css';
import tea from '../../images/tea.png'

const About = () => {
    useEffect(() => {
        AOS.init();
      }, [])

    return (
        <div id="about" className="style-about">
            <div className="style-picture" data-aos="fade-right" data-aos-duration="500" data-aos-offset="130">
              <img className="style-img" src={tea}></img>
            </div>
            <div className="style-info" data-aos="fade-right" data-aos-duration="500" data-aos-offset="130">
              <h1 className="style-about-heading"> About Us. </h1>
              <div className="style-paragraph-container">
                <p className="style-main-paragraph"> A legacy of crafting premium black CTC tea since 1947, 
                our tea estate is renowned for its consistent quality and artisanal approach to tea-making.</p>
                <p className="style-second-paragraph" align="justify">
                    We are a family-owned tea estate
                    located in the prime tea growing area of Assam, India. 
                    We believe that the art of tea-making is as much about the care and attention we
                    give to our gardens as it is about the final product.
                    We take pride in carefully cultivating and harvesting the finest tea leaves to create
                    a grade of pure single estate origin teas that are both comforting and invigorating. 
                    Our unwavering commitment to sustainability is reflected in every aspect of our farming
                    practices. Not only does it ensure the health of our community, but also results in teas 
                    that truly reflect the unique terroir of our estate.<br></br><br></br>
                    <span text-indent="15px">Whether you're a tea connoisseur or a casual drinker, we have something for everyone.
                    We invite you to indulge in the unparalleled experience of our premium CTC teas, and discover 
                    for yourself the exquisite nuances that set us apart.</span></p>
                </div>
            </div>
        </div>
    )
}

export default About
