import React, {useEffect} from 'react'
import './styleLanding.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import sample from '../../videos/Beheating-movie.mp4'

export const LandingComponent = () => {
   useEffect(() => {
        AOS.init();
      }, [])

    return (
        <div id="home" className="style-landing" >
          <div className="style-overlay"></div>
          <video src={sample} className="style-video" autoPlay loop muted/>
          <div className="style-content">
            <h2 className="style-heading" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="130">EXPERIENCE THE DEPTH OF</h2>
            <h1 className="style-sub-heading" data-aos="zoom-in" data-aos-duration="1000" data-aos-offset="130">Premium CTC  Tea </h1>
          </div>
          </div>
    )
}

export default LandingComponent
