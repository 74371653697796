import teaPlucking from './tea-plucking.png'
import teaTasting from './tea-tasting.jpeg'
import ctc from './ctc.jpeg'
import sorting from './sorting.jpeg'
import packing from './packing.jpeg'
import factory from './factory.jpeg'
import plucking2 from './plucking2.jpeg'

export const photos = [
  {
    src: teaPlucking,
    width: 2,
    height: 2
  },
  {
    src: sorting,
    width: 3,
    height: 3
  },
  {
    src: plucking2,
    width: 3,
    height: 3
  },
  {
    src: factory,
    width: 3,
    height: 3
  },
  {
    src: packing,
    width: 3,
    height: 3
  },
  {
    src: teaTasting,
    width: 3,
    height: 3
  },
  {
    src: ctc,
    width: 2,
    height: 2
  }
];