import './App.css';
import React, { useEffect, useState } from "react";
import NavbarComponent from './Components/Navbar/navbar';
import AboutComponent from './Components/About/about';
import TeaComponent from './Components/Tea/tea';
import SpinnerComponent from './Components/Spinner/spinner';
import LandingComponent from './Components/Landing/landing';
import ContactUs from './Components/ContactUs/contactUs';
import Footer from './Components/Footer/footer';

function App() {

  const [spinner, setSpinner] = useState(true)

  useEffect(() => {
    setTimeout(() => { // after some fake time, component will stop with render
        setSpinner(false);
    }, 3500);
  }, []);

  if(spinner)
  return <SpinnerComponent/>

  return (
    <>
    <NavbarComponent/>
    <LandingComponent/>
    <AboutComponent/>
    <TeaComponent/>
    <ContactUs/>
    <Footer/>
     </>
   
  );
}

export default App;
