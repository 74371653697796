import React from 'react'
import './styleTea.css';
import Gallery from "react-photo-gallery"
import { photos } from "./photos";
import nonGMO from "../../images/nongmo.png"
import sustain from "../../images/sustainable.png"
import quality from "../../images/quality-check.png"


const Tea = () => {
    return (
        <div id="tea" className="style-tea-main">
            <div className="style-tea-info" data-aos="fade-right" data-aos-duration="500" data-aos-offset="130">
              <h1 className="style-tea-heading">Our CTC Teas</h1>
              <p className="style-tea-paragraph" align="justify"><b>CTC</b> 
              (Crush, Tear, Curl) tea is a popular type of black tea that is widely consumed 
              all over the world. It is a process that involves rolling, breaking and shaping the tea leaves 
              which creates a perfect cup. Known for its versatility, it can be enjoyed both plain or 
              with milk and sugar, making it a popular choice for tea drinkers of all tastes.</p>
              <p className="style-tea-paragraph-2" align="justify">From the strong and robust flavor of our OF (Orange Pekoe Fannings) grade, to the fine and 
              delicate taste of our BOPSM (Broken Orange Pekoe Small), we have something to 
              suit every taste preference. Additionally our Dust, PD, BOP and BP grades are also great options 
              for those who are looking for a flavorful cup of tea without any fuss. Our teas are 
              available in a range of grades and are perfect for use in tea bags, blends, and as a standalone tea. </p>
              <p className="style-tea-paragraph-2" align="justify">So why choose CTC tea? It is perfect for those who appreciate a strong, full-bodied cup
               of tea and who want a tea that is easy to brew and quick to prepare. It is also a great choice for 
               those who are looking for a tea that is affordable, yet still high in quality. We look forward to the opportunity to provide
              you with the finest teas from our estate.
 </p>
              <div className="style-symbols-container">
                <div className="style-item">
                  <img className="style-symbols" src={nonGMO}/>
                  <span className="style-caption">NON-GMO</span>
                </div>
                <div className="style-item">
                  <img className="style-symbols" src={sustain}/>
                  <span className="style-caption">SUSTAINABLE</span>
                </div>
                <div className="style-item">
                  <img className="style-symbols" src={quality}/>
                  <span className="style-caption">QUALITY CHECK</span>
                </div>

              </div>  

            </div>
            <div className="style-gallery" data-aos="fade-right" data-aos-duration="500" data-aos-offset="130">
              <Gallery photos={photos} />
            </div>
        </div>
    )
}

export default Tea
