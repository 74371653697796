import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div className="style-footer-main">
           <p className="style-footer">© <b>Beheating Tea Estate</b> 2023</p>
        </div>
    )
}

export default Footer
