import React, {useState} from 'react'
import './styleNavbar.css'
import {Nav, Navbar, Container} from 'react-bootstrap';
import { Link, animateScroll as scroll } from "react-scroll";
import logo from '../../images/logo.png'

export const NavbarComponent = ({setNavItem, ref}) => {
    const handleClick = (sectionId) => {
    scroll.scrollTo(document.getElementById(sectionId).offsetTop, {
        duration: 1000,
        smooth: "easeInOutQuart"
    });
    setNavItem(sectionId);
  }
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if(window.scrollY >=200) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  }

  window.addEventListener('scroll', changeBackground);

  var divStyle = {
    fontWeight: 'bold'
  }

    return (
      <>
      <Navbar expand="sm" collapseOnSelect className={navbar ? 'fixed-top style-navbar active':'fixed-top style-navbar'} ref={ref}>
        <Container>
          <Navbar.Brand>
            <img className="style-logo" src={logo} alt="Logo"/>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse id="scrollspy">
            <Nav className="m-auto">
            <Nav.Item className="style-navitem">
              <Link style={{ textDecoration: 'none' }} activeStyle={{'fontWeight': 'bold'}} activeClass="active" to="home" spy={true} smooth={true} offset={-100} duration={250}><p className="style-navlink">HOME</p></Link>
            </Nav.Item>
            <Nav.Item className="style-navitem">
              <Link style={{ textDecoration: 'none' }} activeStyle={{'fontWeight': 'bold'}} activeClass="active" to="about" spy={true} smooth={true} offset={-100} duration={250}><p className="style-navlink">ABOUT</p></Link>
            </Nav.Item>
            <Nav.Item className="style-navitem">
              <Link style={{ textDecoration: 'none' }} activeStyle={{'fontWeight': 'bold'}} activeClass="active" to="tea" spy={true} smooth={true} offset={-100} duration={250}><p className="style-navlink">TEA</p></Link>
              </Nav.Item>
            </Nav>
            <Nav id="scrollspy">
            <Nav.Item className="style-navitem">
              <Link style={{ textDecoration: 'none' }} activeStyle={{'fontWeight': 'bold'}} activeClass="active" to="contact" spy={true} smooth={true} offset={-100} duration={250}><p className="style-navlink">CONTACT</p></Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </>
    )
};

export default NavbarComponent;

