import React, { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './styleContactUs.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Button, Form, FloatingLabel} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLocationDot, faPhone, faEnvelope, faIndustry} from '@fortawesome/free-solid-svg-icons';

const ContactUs = () => {
    const form = useRef();
    useEffect(() => {
      AOS.init();
    }, [])
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_wcfdtt7', 'template_t1aqrdf', form.current, 'H-ed9tYldcYOnSMro')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };
  
    return (
    <div id="contact" className="style-maincontactus">
      <div className="style-contactus">
        <div data-aos="zoom-in" data-aos-duration="500" data-aos-offset="130">
          <h1 className="style-heading-contactus">Contact Us.</h1>
          <p align="justify" className="style-para-contactus"> 
            We would love to hear from you! For any inquiries, please don't 
            hesitate to get in touch via our website's contact 
            form, email or phone call.</p>
            <div className="style-details-block">
              <FontAwesomeIcon size="1x" className="style-icon" icon={faLocationDot}/>
              <p className="style-contact-info">Beheating Tea Estate Commerce House, 
              3rd Floor, Room 4 & 4A, 2 Ganesh Chandra Avenue Kolkata - 700013, India</p><br></br>
              <FontAwesomeIcon size="1x" className="style-icon" icon={faIndustry}/> <p className="style-contact-info"> Jamirah-Bogibeel Road, Dibrugarh - 786004 (Assam), India </p><br></br>
              <FontAwesomeIcon size="1x" className="style-icon" icon={faPhone}/> <p className="style-contact-info">+91 332213-2955</p><br></br>
              <FontAwesomeIcon size="1x" className="style-icon" icon={faEnvelope}/> <p className="style-contact-info">info@beheatingtea.com </p>
          </div>
        </div>
      </div>
      <div className="style-form">
        <div className="style-inner-form" data-aos="zoom-in" data-aos-duration="500" data-aos-offset="-10">
          <Form ref={form} onSubmit={sendEmail}>
            <div className="style-input-form">
              <FloatingLabel label="Your Name" className="style-label mb-3">
                <Form.Control type="text" name="user_name" placeholder="Your Name" id="style-input" required/>
              </FloatingLabel>
              <FloatingLabel label="Email Address" className="style-label mb-3">
                <Form.Control type="email" name="user_email" placeholder="Email Address" id="style-input" required/>
              </FloatingLabel>
              <FloatingLabel label="Website" className="style-label mb-3">
                <Form.Control type="text" name="user_website" id="style-input" placeholder="Website"/>
              </FloatingLabel>
              <FloatingLabel label="Leave a comment here" className="style-label">
                <Form.Control as="textarea" name="message" id="style-input" placeholder="Message" style={{ height: '150px' }}/>
              </FloatingLabel>
              <div className="style-button-div"><Button variant="outline-dark" className="style-button" type="submit">Send</Button></div>
            </div>
          </Form>
        </div>
      </div>
    </div>
    );
  };

  export default ContactUs